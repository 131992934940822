<template>
  <div>
    <div class="filter-box">
      <ul class="filter-ul">
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.createStaffId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.orgId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.readFlag"
        />
        <select-picker
          v-else
          :data="filterData.status"
        />
        <date-picker
          :data="filterData.startDate"
          :week-interval="weekInterval"
          :filter-data="filterData"
        />
      </ul>
    </div>
    <!-- <div class="filter-box">
    <ul class="filter-ul">
      <date-picker :data="filterData.startDate"></date-picker>
      <date-picker :data="filterData.endDate"></date-picker>
    </ul>
  </div> -->
    <div class="bottom-postion">
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import datePicker from '_c/picker/date';
import selectPicker from '_c/picker/select';

export default {
  components: {
    selectPicker,
    datePicker
  },
  data() {
    return {
      filterData: {
        createStaffId: {
          title: '创建人',
          name: '',
          value: '',
          dap: false,
          typeName: 'createStaffId'
        },
        orgId: {
          title: '部门',
          name: '',
          value: '',
          dap: false,
          typeName: 'orgId'
        },
        readFlag: {
          title: '状态',
          name: '',
          value: '',
          options: []
        },
        status: {
          title: '状态',
          name: '',
          value: '',
          options: []
        },
        startDate: {
          title: '创建时间',
          name: '',
          value: '',
          min: [2000, 1, 1],
          max: [2050, 10, 20]
        },
        endDate: {
          title: '创建结束时间',
          name: '',
          value: '',
          min: [2000, 1, 1],
          max: [2050, 10, 20]
        }
      },
      weekInterval: true
    };
  },
  computed: {
    ...mapGetters('workreport', ['filterObj']),
    ...mapGetters(['dictList', 'branchList'])
  },
  mounted() {
    this.getOptions();
    this.getSellData();
  },
  methods: {
    ...mapMutations('workreport', ['WORKREPORT_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        this.filterData[i].value = '';
      }
      this.CLEAR_FILTER('workreport');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value
        };
      }
      this.WORKREPORT_FILTER(filterData);
    },
    toTree(typeName) {
      this.cache();
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName
          }
        },
      );
    },
    getSellData() {
      if (JSON.stringify(this.filterObj) !== '{}') {
        for (const i in this.filterObj) {
          this.filterData[i].name = this.filterObj[i] ? this.filterObj[i].name : '';
          this.filterData[i].value = this.filterObj[i] ? this.filterObj[i].value : '';
        }
      }
    },
    getOptions() {
      this.filterData.readFlag.options = this.dictList.readFlag;
      this.filterData.status.options = this.dictList.status;
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
